/*globals $ */

class HeroImg {
  constructor(manager, el) {
    this.manager = manager;
    this.$el = el;
    this.isSpView = this.manager.isSpView;
    this.prevSpView = this.isSpView;
    this.src_original = this.$el.attr('data-original');

    this.isFadein = false;
  }
  load() {
    $('<img>')
      .one('load', () => {
        this.manager.loadCompleteNum++;
        this.$el.css('background-image', 'url("' + this.src_original + '")');

        this.$el.loaded = true;
        this.$el.addClass('loaded');
        this.manager.loadNext();
      })
      .attr('src', this.src_original);
  }
  fadein(depth) {
    if (!this.isFadein) {
      this.isFadein = true;
      this.$el.stop(false).css({ display: 'block', 'z-index': depth });
      setTimeout(() => {
        this.$el.addClass('-fadein');
      }, 50);
    }
  }
  fadeout() {
    if (this.isFadein) {
      this.isFadein = false;
      this.$el.removeClass('-fadein');
    }
  }
}

class HeroSlideshow01 {
  constructor(pageApp) {
    this.$window = $(window);
    this.pageApp = pageApp;

    if (window.matchMedia('(max-width: 750px)').matches) {
      this.isSpView = true;
    } else {
      this.isSpView = false;
    }
    this.prevSpView = this.isSpView;

    this.slideshowPc = new SlideshowCore($('.HeroSlideshow--pc'));
    this.slideshowSp = new SlideshowCore($('.HeroSlideshow--sp'));

    this.$window.on('resize', () => {
      this.onWindowResize();
    });
    this.onWindowResize();
  }

  start() {
    if (this.isSpView) this.slideshowSp.start();
    else this.slideshowPc.start();
  }

  onWindowResize() {
    if (window.matchMedia('(max-width: 750px)').matches) {
      this.isSpView = true;
    } else {
      this.isSpView = false;
    }

    if (this.prevSpView != this.isSpView) {
      if (this.isSpView) {
        this.slideshowPc.stop();
        this.slideshowSp.start();
      } else {
        this.slideshowSp.stop();
        this.slideshowPc.start();
      }
    }

    this.prevSpView = this.isSpView;
  }
}
export default HeroSlideshow01;

class SlideshowCore {
  constructor($container) {
    this.$container = $container;
    this.images = [];
    this.depth = 0;
    this.currentIndex = 0;

    this.$container.find('.HeroSlideshowImg').each((i, e) => {
      this.images.push(new HeroImg(this, $(e)));
    });
    this.loadCompleteNum = 0;

    this.images[0].load();
  }
  loadNext() {
    if (this.loadCompleteNum < this.images.length) {
      this.images[this.loadCompleteNum].load();
    }
  }
  stop() {
    this.$container.removeClass('is-start');
    clearInterval(this.slideInterval);
  }
  start() {
    this.$container.addClass('is-start');
    // this.pageApp.onWindowResize();
    this.depth++;
    this.images[this.currentIndex].fadein(this.depth);

    this.slideInterval = setInterval(() => {
      this.next();
    }, 6000);
  }
  next() {
    this.depth++;
    this.currentIndex++;
    if (this.currentIndex == this.images.length) this.currentIndex = 0;
    for (let i = 0; i < this.images.length; i++) {
      if (i == this.currentIndex) {
        this.images[this.currentIndex].fadein(this.depth);
      } else {
        this.images[i].fadeout();
      }
    }
  }
}
