/*globals $ */

class PageSingle {
  constructor(mainApp) {
    this.mainApp = mainApp;

    this.$sectionFooter = $('.ScrollSectionFooter');

    $('.RTE').find('iframe').each((i, e) => {
      if(String($(e).attr('src')).indexOf('youtube') != -1) {
        let originalIframe = $(e);
        let $embedDiv = $('<div class="EmbedWrap"><div class="EmbedWrap__inner"></div></div>');
        $(e).replaceWith($embedDiv);
        $embedDiv.children('.EmbedWrap__inner').append(originalIframe);
      }
    });
  }
  start() {
  }
  onWindowResize() {
  }
  onWindowScroll(scrollTop) {
    if(scrollTop + window.innerHeight > this.$sectionFooter.offset()['top']) {
      this.$sectionFooter.addClass('ScrollSectionFooter--sticky');
    } else {
      this.$sectionFooter.removeClass('ScrollSectionFooter--sticky');
    }

    if($('.ScrollSectionWindow--static').height() < window.innerHeight) {
      $('.ScrollSectionFooter').addClass('ScrollSectionFooter--min-height');
    } else {
      $('.ScrollSectionFooter').removeClass('ScrollSectionFooter--min-height');
    }
  }
}

export default PageSingle;
