/*globals $ */

class LogoTq {
  constructor() {
    this.$container = $('.LogoTq');

    this.$el = $('#logo--tq');
    this.elements = [];
    this.fadeinCount = 0;
    this.isFadein = false;

    this.$el.children('g').each((i, e) => {
      this.elements.push($(e));
    });

    for(let i = 0; i < this.elements.length; i++) {
      this.elements[i].css('opacity', 0);
    }
  }

  fadein() {
    if(!this.isFadein) {
      this.isFadein = true;
      this.fadeinCount = 0;

      // this.$container.css('opacity', 1);
      this.$container.addClass('LogoTq--show');

      // $('.Background').addClass('Background--blue');

      this.fadeinInterval = setInterval(() => {
        this.elements[this.fadeinCount].css('opacity', 1);
        this.fadeinCount++;

        if(this.fadeinCount == this.elements.length) {
          clearInterval(this.fadeinInterval);
        }
      }, 60);
    }

  }
  fadeout() {
    if(this.isFadein) {
      this.isFadein = false;
      clearInterval(this.fadeinInterval);
      this.$container.removeClass('LogoTq--show');

      // $('.Background').removeClass('Background--blue');

      for(let i = 0; i < this.elements.length; i++) {
        this.elements[i].css('opacity', 0);
      }
    }
  }
}

export default LogoTq;
