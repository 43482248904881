

import SvgImg from './SvgImg';

class InViewObj {
  constructor($el) {
    this.$el = $el;
    this.isSvgImg = false;

    if(this.$el.hasClass('SvgImg')) {
      this.svgImg = new SvgImg(this.$el);
      this.isSvgImg = true;
    }
  }
  fadein() {
    if(this.isSvgImg) {
      this.svgImg.fadein();
    } else {
      this.$el.css('opacity', 1);
    }
  }
  fadeout() {
    if(this.isSvgImg) {
      this.svgImg.fadeout();
    } else {
      this.$el.css('opacity', 0);
    }
  }
}


export default InViewObj;
