/*globals $ */

import InViewObj from './InViewObj';
import PerfectScrollbar from 'perfect-scrollbar';

class Modal {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$container = $('.Modal');
    this.fadeinObjects = [];
    // this.$bottomLink = $('.BottomLink');
    this.isFadein = false;
    new PerfectScrollbar('#ModalScroller', {
      suppressScrollX: true
    });

    $('.BtnCloseModal').on('click', () => {
      this.close();
      return false;
    });

    this.$container.find('.InViewObj').each((i, e) => {

      this.fadeinObjects.push(new InViewObj($(e)));
    });
  }
  offTouchEvent(e) {
    e.preventDefault();
  }

  open() {
    if(!this.isFadein) {
      /*
      $(document).on('touchmove.noScroll', (e) => {
          e.preventDefault();
      }, {passive: false});
      */

     document.addEventListener('touchmove', this.offTouchEvent, {passive: false});
      this.tmpScrollTop = this.mainApp.scrollTop;
      $('html').css('overflow-y', 'hidden');
      $('body').addClass('menu-open');
      this.isFadein = true;


      this.$container.css({
        'display': 'block'
      });
      $('#ModalScroller')[0].scrollTop = 0;

      // this.$bottomLink.addClass('BottomLink--fadein');
      // clearInterval(this.fadeinInterval);
      /*
      this.fadeinCount = 0;
      this.fadeinInterval = setInterval(() => {
        this.fadeinObjects[this.fadeinCount].fadein();//css('opacity', 1);
        this.fadeinCount++;

        if(this.fadeinCount == this.fadeinObjects.length) {

          clearInterval(this.fadeinInterval);
        }
      }, 60);
      */
    }
  }
  close() {
    if(this.isFadein) {
      // this.$bottomLink.removeClass('BottomLink--fadein');
      document.removeEventListener('touchmove', this.offTouchEvent);
      $('html').css('overflow-y', 'visible');
      this.mainApp.scrollPage(this.tmpScrollTop, true);
      this.mainApp.onWindowResize();

      $('body').removeClass('menu-open');
      this.isFadein = false;
      this.$container.css({
        'display': 'none'
      });

      /*
      clearInterval(this.fadeinInterval);

      for(let i = 0; i < this.fadeinObjects.length; i++) {
        this.fadeinObjects[i].fadeout();
      }
      */
    }
  }
}

export default Modal;
