/*globals $ */

import SvgImg from "./SvgImg";

class Logo {
  constructor() {
    this.$container = $('.Logo');

    // console.log(this.$container.children('a').children('svg'));
    // this.$el = $('#logo--main');
    this.$el = this.$container.children('a').children('svg').children('g');//$('#logo--main');
    this.elements = [];
    this.fadeinCount = 0;

    this.hasChildLogo = false;

    if(this.$container.find('.Logo__child').length) {
      this.childLogo = new SvgImg(this.$container.find('.Logo__child'));
      this.hasChildLogo = true;
    }

    this.$el.children('g').each((i, e) => {
      this.elements.push($(e));
    });

    for(let i = 0; i < this.elements.length; i++) {
      this.elements[i].css('opacity', 0);
    }
  }

  fadein(completeHandler = () => {}) {
    this.$container.css('opacity', 1);

    this.fadeinInterval = setInterval(() => {
      this.elements[this.fadeinCount].css('opacity', 1);
      this.fadeinCount++;

      if(this.fadeinCount == this.elements.length) {
        clearInterval(this.fadeinInterval);
        if(this.hasChildLogo) {
          this.childLogo.fadein();
        }
        completeHandler();
      }
    }, 60);

  }
}

export default Logo;
