/*globals $ */

import SvgImg from './SvgImg';

class SectionTitleItem {
  constructor($el) {
    this.$el = $el;
    this.target = this.$el.attr('data-target');
    this.svgImg = new SvgImg(this.$el.find('.SvgImg'));
    this.isFadein = false;
  }

  fadein() {
    if(!this.isFadein) {
      this.isFadein = true;
      this.svgImg.fadein();
    }
  }
  fadeout() {
    if(this.isFadein) {
      this.isFadein = false;
      this.svgImg.fadeout();
    }
  }
}

class SectionTitle {
  constructor() {
    this.$container = $('.SectionTitle');

    this.items = [];

    this.$container.find('.SectionTitleItem').each((i, e) => {
      this.items.push(new SectionTitleItem($(e)));
    });
  }

  fadein(target) {
    for(let i = 0; i < this.items.length; i++) {
      if(this.items[i].target == target) {
        this.items[i].fadein();
      } else {
        this.items[i].fadeout();
      }
    }
  }
  fadeout() {
    for(let i = 0; i < this.items.length; i++) {
      this.items[i].fadeout();
    }
  }
}

export default SectionTitle;
