/*globals $ */

// import News from './News';
import SectionTitle from './SectionTitle';
import ScrollSection from './ScrollSection';
import HeroSlideshow01 from './HeroSlideshow01';


class PageKvtest {
  constructor(mainApp) {
    this.mainApp            = mainApp;

    this.$logo              = $('.Logo a');
    this.$copy              = $('.MainCopy img');
    this.$firstview         = $('.Firstview')
    this.$hero              = $('.Hero');
    this.$bottomContents    = $('.BottomContents');

    this.sectionTitle       = new SectionTitle();
    this.hasNews            = false;
    this.isHome             = false;
    this.isStart            = false;

    // this.logoOffsetTop      = this.$logo.offset()['top'];
    // this.logoHeight         = this.$logo.height();
    // this.logoBottomPos      = this.logoOffsetTop + this.logoHeight;

    this.logoSlideValue     = 0;
    this.copySlideValue     = 0;
    this.sections           = [];
    this.slideshow = undefined;


    if($('.HeroSlideshow--01').length || $('.HeroSlideshow--02').length || $('.HeroSlideshow--03').length) {
      this.slideshow = new HeroSlideshow01(this);
    }


    if(this.hasNews) {
      this.heroBottomPos = $('.News').offset()['top'] - 30;
    } else {
      if(this.isHome) {
        this.heroBottomPos = 0;
      } else {
        this.heroOffsetTop = this.$hero.offset()['top'];
        this.heroHeight = this.$hero.outerHeight();
        this.heroBottomPos = Math.floor(this.heroOffsetTop + this.heroHeight);
      }
      // this.heroOffsetTop = this.$hero.offset()['top'];
      // this.heroHeight = this.$hero.outerHeight();
      // this.heroBottomPos = Math.floor(this.heroOffsetTop + this.heroHeight);
    }

    $('.ScrollSection').each((i, e) => {
      this.sections.push(new ScrollSection(this, $(e)));
    });
  }

  start() {
    if(this.hasNews) {
      this.news.fadein();
    } else {
      this.$hero.delay(350).animate({'opacity':1}, 600, 'easeInOutExpo');
    }
    if(this.slideshow != undefined) {
      this.slideshow.start();
    }

    setTimeout(() => {
      $('.MainCopy').addClass('MainCopy--fadein');
      setTimeout(() => {
        this.onWindowResize();
        window.isCancelScroll = false;

        if($('body').hasClass('home')) {
          if(location.hash.length > 2) {
            let hash = String(location.hash).slice(1);
            if(hash != 'news') {
              let $targetSection = $('.ScrollSection[data-id="' + hash + '"]');
              // let targetPaddingTop = Math.floor(parseInt($targetSection.find('.ScrollSectionWindow').css('padding-top')) / 2);
              let targetPaddingTop = parseInt($targetSection.find('.ScrollSectionWindow').css('padding-top'));
              this.mainApp.scrollPage($targetSection.offset()['top'] + targetPaddingTop);
            }
          }
        }

      }, 1300);
    }, 1000);



    // this.mainApp.logoTq.fadein();
    this.isStart = true;
  }

  onWindowScroll(scrollTop) {

    if(this.logoBottomPos > this.heroBottomPos - scrollTop) {
      this.logoSlideValue = this.logoBottomPos - (this.heroBottomPos - scrollTop);
    } else {
      this.logoSlideValue = 0;
    }


    if(window.matchMedia('(max-width: 750px)').matches) {
      this.copySlideValue = scrollTop;
    } else {
      if(this.copyBottomPos > this.heroBottomPos - scrollTop) {
        this.copySlideValue = this.copyBottomPos - (this.heroBottomPos - scrollTop);
      } else {
        this.copySlideValue = 0;
      }
    }

    if(this.logoSlideValue >= 0) {
      this.$logo.css('transform', 'translate3d(0, -' + this.logoSlideValue + 'px, 0)');
    } else {
      this.$logo.css('transform', 'translate3d(0, 0, 0);');
    }

    if(this.copySlideValue >= 0) {
      this.$copy.css('transform', 'translate3d(0, -' + this.copySlideValue + 'px, 0)');
    } else {
      this.$copy.css('transform', 'translate3d(0, 0, 0);');
    }

    this.fadeinSection = -1;
    for(let i = 0; i < this.sections.length; i++) {
      if(this.sections[this.sections.length - 1 - i].$container.offset()['top'] < scrollTop + 10) {
        this.fadeinSection = this.sections.length - 1 - i;
        break;
      }
    }
    /*
    if(this.fadeinSection == -1 || this.fadeinSection == this.sections.length - 1) {
      $('.Background').attr('data-bg', '');
      this.mainApp.logoTq.fadeout();
    } else {
      this.mainApp.logoTq.fadein();
    }
    */
    if(this.fadeinSection == -1) {
      $('.Background').attr('data-bg', '');
      this.mainApp.logoTq.fadeout();
    } else if(this.fadeinSection == this.sections.length - 1) {
      $('.Background').attr('data-bg', 'black');
      this.mainApp.logoTq.fadeout();
    } else {
      this.mainApp.logoTq.fadein();
    }



    if(this.fadeinSection == -1) {
      this.sectionTitle.fadeout();
      for(let i = 0; i < this.sections.length; i++) {
        this.sections[i].fadeout();
      }
    } else {
      for(let i = 0; i < this.sections.length; i++) {
        if(i == this.fadeinSection) {
          this.sections[i].fadein();
        } else {
          this.sections[i].fadeout();
        }
      }
    }


  }
  onWindowResize() {
    if(!this.isHome) {
      this.logoOffsetTop = $('.Logo').position()['top'];// this.$logo.position()['top'];
      this.logoHeight = this.$logo.height();
      this.logoBottomPos = this.logoOffsetTop + this.logoHeight;
      this.copyOffsetTop = $('.MainCopy').position()['top'];// this.$copy.offset()['top'];
      this.copyHeight = this.$copy.height();
      this.copyBottomPos = this.copyOffsetTop + this.copyHeight;

      let obj = {
        'logoOffsetTop': this.logoOffsetTop,
        'logoHeight': this.logoHeight,
        'logoBottomPos': this.logoBottomPos
      };

    } else {
      this.logoBottomPos = 0;
    }


    if(this.hasNews) {
      // this.heroOffsetTop = 0;//this.$hero.offset()['top'];
      // this.heroHeight = this.$hero.outerHeight();
      this.heroBottomPos = $('.News').offset()['top'] - 30;//Math.floor(this.heroOffsetTop + this.heroHeight);
    } else {
      if(this.isHome) {
        this.heroBottomPos = 0;
      } else {
        this.heroOffsetTop = this.$hero.offset()['top'];
        this.heroHeight = this.$hero.outerHeight();
        this.heroBottomPos = Math.floor(this.heroOffsetTop + this.heroHeight);
      }
    }

    for(let i = 0; i < this.sections.length; i++) {
      this.sections[i].onWindowResize();
    }

    $('.TopSection--footer').css('height', window.innerHeight);
  }
}

export default PageKvtest;
